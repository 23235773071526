import { getRootId } from '../../helpers';

// const EXTRA_LIGHT_COLOR = '#00d3e7';
const LIGHT_COLOR = '#00bbc8';
const MEDIUM_COLOR = '#0098A7';
const DARK_COLOR = '#00474e';

const FLOOR_MEASUREMENT_COLOR = {
  Espresso: MEDIUM_COLOR,
  Chestnut: LIGHT_COLOR,
  SaddleBrown: LIGHT_COLOR,
  CoastalGrey: DARK_COLOR,
  WhiteOak: MEDIUM_COLOR,
};

const isMobile = (function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
})();
const FONT_SIZE = isMobile ? 7.4 : 3.4;
const Y_OFFSET = isMobile ? -0.03 : 0;
const XZ_OFFSET = isMobile ? 0.03 : 0;
const LINE_OFFSET = 0.12 + XZ_OFFSET;

const state = {
  floor: undefined,
  rulers: new Map(), // itemId --> start, end
  activeRuler: null,
  enabled: false,
  step: 0,
  boxes: [],
};

export const addRuler = async (type) => {
  // TODO
  // 1. Reset if one is on-going
  // 2. Create Line Dimensions, create pin when necessary (hidden)
  // 3. Enable tool that moves pins when moving mouse
  // 4. Enable tool that handles clicking:
  //    Show Line Dimensions, set Start to where mouse clicked at
  //    (the move tool moves End to where mouse goes), place End
  //    on the next click
  const parentId = await getRootId();

  const index = state.rulers.size;
  // create nulls
  const pinPlugs = {
    Null: [
      {
        type: 'Model',
        asset: {
          query: { metadata: { itemType: 'tool', itemKey: 'pin' } },
        },
      },
    ],
    Properties: [{ type: 'ModelProperties', visible: false }],
  };
  const startPinId = window.threekit.api.scene.addNode(
    {
      type: 'Model',
      name: `Pin_Start_${index}`,
      plugs: pinPlugs,
    },
    parentId
  );
  const endPinId = window.threekit.api.scene.addNode(
    {
      type: 'Model',
      name: `Pin_End_${index}`,
      plugs: pinPlugs,
    },
    parentId
  );

  const plugs = {
    Measurement: [
      {
        unit: 'in',
        name: 'Ruler',
        type: 'Ruler',
        node0: startPinId,
        node1: endPinId,
      },
    ],
    // Properties: [{ type: 'ModelProperties', visible: true }],
  };

  const nodeId = window.threekit.api.scene.addNode(
    {
      type: 'Measurement',
      name: `Line Dimensions`,
      plugs,
    },
    parentId
  );

  const itemId = nodeId;
  state.rulers.set(itemId, { start: startPinId, end: endPinId });
  state.activeRuler = itemId;
  state.enabled = true;
  state.step = 0;
};

export const removeRulers = () => {
  // TODO
  state.rulers.forEach((value, key) => {
    console.log(value, key);
    window.threekit.api.scene.deleteNode(key);
    Object.values(value).map((id) => window.threekit.api.scene.deleteNode(id));
    state.rulers.delete(key);
  });
};

export const enableMeasurement = (type) => {
  state.enabled = true;
  // Show measurement of sets
  updateSets();

  // TODO enable adding rulers
};

export const disableMeasurement = () => {
  state.enabled = false;
  state.activeRuler = null;
  state.boxes.map((box) =>
    window.threekit.api.scene.set(
      {
        id: box.id,
        plug: 'Measurement',
        property: 'targets',
      },
      []
    )
  );
  removeRulers();
};

const getMeasurementColor = (floor) =>
  FLOOR_MEASUREMENT_COLOR[floor || state.floor] || MEDIUM_COLOR;

export const updateMeasurementColor = (floorOrColor) => {
  const color = getMeasurementColor(floorOrColor);
  if (FLOOR_MEASUREMENT_COLOR[floorOrColor]) state.floor = floorOrColor;
  const { api } = window.threekit;
  state.boxes.map((box) =>
    api.scene.set(
      {
        id: box.id,
        plug: 'Measurement',
        property: 'color',
      },
      FLOOR_MEASUREMENT_COLOR[floorOrColor] || floorOrColor || color
    )
  );
};

export const initFloor = (floor) => {
  state.floor = floor;
};

export const updateSets = async (sets) => {
  const { api } = window.threekit;
  const { enabled } = state;

  if (!sets) sets = state.sets;
  else state.sets = sets;

  if (enabled) {
    const currentNumOfBoxes = state.boxes.length;
    if (sets.length > currentNumOfBoxes) {
      const parentId = await getRootId();
      await Promise.all(
        sets.map(async (set, index) => {
          const seats = await Promise.all(
            Array.from(set.seats).map(async (seatId) => {
              const instanceId = await api.player.getAssetInstance({
                id: seatId,
                plug: 'Null',
                property: 'asset',
              });
              const assetId = await api.player.getAssetInstance({
                id: instanceId,
                plug: 'Proxy',
                property: 'asset',
              });
              const nullId =
                api.scene.findNode({
                  from: assetId,
                  name: 'SactionalBase_Nulls',
                }) ||
                api.scene.findNode({
                  from: assetId,
                  name: 'SactionalWedgeBase_Nulls', // Wedge seat null
                });
              return nullId;
            })
          );
          const sides = await Promise.all(
            Array.from(set.sides).map(async (seatId) => {
              const instanceId = await api.player.getAssetInstance({
                id: seatId,
                plug: 'Null',
                property: 'asset',
              });
              const assetId = await api.player.getAssetInstance({
                id: instanceId,
                plug: 'Proxy',
                property: 'asset',
              });
              const nullId = api.scene.findNode({
                from: assetId,
                name: 'Side Model',
              });
              return nullId;
            })
          );
          const targets = seats.concat(sides);
          if (index < currentNumOfBoxes) {
            // update target nodes
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'targets',
              },
              targets
            );
          } else {
            const color = getMeasurementColor();
            // create new boxes
            const boxPlugs = {
              Measurement: [
                {
                  targets,
                  type: 'BoundingBox',
                  unit: 'in',
                  decimals: 1,
                  xEnabled: true,
                  xOffset: { x: 0, y: Y_OFFSET, z: LINE_OFFSET },
                  xPositioning: 'bottom',
                  yEnabled: true,
                  yOffset: { x: LINE_OFFSET, y: 0, z: LINE_OFFSET },
                  yPositioning: 'left',
                  zEnabled: true,
                  zOffset: { x: LINE_OFFSET, y: Y_OFFSET, z: 0 },
                  zPositioning: 'bottom',
                  space: 'world',
                  lineThickness: 0.75,
                  fontSize: FONT_SIZE,
                  fontCSSSpecifier:
                    "'museo-sans','Helvetica Neue',Helvetica,Arial,sans-serif",
                  color,
                },
              ],
              Properties: [{ type: 'ModelProperties', visible: true }],
            };
            const nodeId = api.scene.addNode(
              {
                type: 'Measurement',
                name: `Box Dimensions ${index}`,
                plugs: boxPlugs,
              },
              parentId
            );
            state.boxes.push({ id: nodeId });
          }
        })
      );
    } else {
      await Promise.all(
        new Array(currentNumOfBoxes).fill(0).map(async (v, index) => {
          if (index < sets.length) {
            const set = sets[index];
            const seats = await Promise.all(
              Array.from(set.seats).map(async (seatId) => {
                const instanceId = await api.player.getAssetInstance({
                  id: seatId,
                  plug: 'Null',
                  property: 'asset',
                });
                const assetId = await api.player.getAssetInstance({
                  id: instanceId,
                  plug: 'Proxy',
                  property: 'asset',
                });
                const nullId =
                  api.scene.findNode({
                    from: assetId,
                    name: 'SactionalBase_Nulls',
                  }) ||
                  api.scene.findNode({
                    from: assetId,
                    name: 'SactionalWedgeBase_Nulls', // Wedge seat null
                  });
                return nullId;
              })
            );
            const sides = await Promise.all(
              Array.from(set.sides).map(async (seatId) => {
                const instanceId = await api.player.getAssetInstance({
                  id: seatId,
                  plug: 'Null',
                  property: 'asset',
                });
                const assetId = await api.player.getAssetInstance({
                  id: instanceId,
                  plug: 'Proxy',
                  property: 'asset',
                });
                const nullId = api.scene.findNode({
                  from: assetId,
                  name: 'Side Model',
                });
                return nullId;
              })
            );
            const targets = seats.concat(sides);
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'targets',
              },
              targets
            );
          } else {
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'targets',
              },
              []
            );
          }
        })
      );
    }
  }
};

export const getActiveRuler = () => {
  return state.rulers.get(state.activeRuler);
};

export const getMeasureStep = () => {
  return state.step;
};

export const getActivePins = () => {
  const { start, end } = state.rulers.get(state.activeRuler);
  const pins = [start, end];
  for (let i = 0; i < state.step; i++) {
    pins.shift();
  }
  return pins;
};

export const getEnabled = () => state.enabled;

export const dropPin = () => {
  if (state.step < 2) state.step++;
  console.log(state);
};

export const revertDropPin = () => {
  if (state.step > 0) state.step--;
};

const displayMeasurements = () => {
  state.boxes.map((b) => console.log(threekit.api.scene.get({ id: b.id })));
};

window.displayMeasurements = displayMeasurements;
window.updateMeasurementColor = updateMeasurementColor;
