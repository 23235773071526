const FLOOR_TYPES = [
  'Espresso',
  'Chestnut',
  'SaddleBrown',
  'CoastalGrey',
  'WhiteOak',
];

export const DEFAULT_FLOOR_CONFIG = baFloorToSceneConfig('Espresso');

let state;

export function baFloorToSceneConfig(floorName) {
  if (!floorName) floorName = 'None';
  return {
    Floor: {
      query: { metadata: { floorType: floorName } },
    },
  };
}

export async function setFloor(floorName, updatePlayer = true) {
  console.log(`Threekit: Will set floor to '${floorName}'`, updatePlayer);
  if (floorName && !FLOOR_TYPES.includes(floorName)) {
    throw new Error(`Unsupported flooring '${floorName}'`);
  }

  state = floorName;

  if (updatePlayer) {
    const configurator = await window.threekit.api.getConfigurator();

    await configurator.setConfiguration(baFloorToSceneConfig(floorName));
  }
  console.log(`Threekit: Did set floor to '${floorName}'`, updatePlayer);
}

export function getFloor() {
  return state;
}
