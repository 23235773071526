import { setFloor } from './floor';
import { reset, saveConfiguration } from './persistence';
import { addItem, configureItems, getItems, removeItems } from './items';
import { addEventListener } from './eventListeners';
import { rotateCamera } from './camera';
import { getThumbnail } from './thumbnail';

// must expose same functionality as v1 configurator
export default {
  setFloor,
  addItem,
  configureItems,
  removeItems,
  getItems,
  rotateItems: async () => {}, // note: will no longer be accessible/used in vray sacs configurator
  rotateCamera,
  getThumbnail,
  saveConfiguration,
  reset,
  addEventListener,
};
