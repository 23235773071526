const DRAG_PIXEL_SENSITIVITY = 100;
export const CAMERA_ROTATIONS = ['0', '1', '2', '3', '4', '5', '6', '7'];

export const rotateTool = {
  active: true,
  enabled: true,
  key: 'rotate',
  handlers: {
    drag: (ev) => {
      const startPos = ev.clientX;
      const config = window.threekit.playerConfigurator.getConfiguration();
      const view = config.Rotation || '0';
      const startIndex = CAMERA_ROTATIONS.indexOf(view);
      let deltaIdx = 0;
      return {
        handle: (ev) => {
          const newDeltaIdx = Math.floor(
            (ev.clientX - startPos) / DRAG_PIXEL_SENSITIVITY
          );
          if (newDeltaIdx !== deltaIdx) {
            deltaIdx = newDeltaIdx;
            let newIndex = (startIndex + newDeltaIdx) % 8;
            if (newIndex <= -1) newIndex += 8;
            window.threekit.playerConfigurator.setConfiguration({
              Rotation: CAMERA_ROTATIONS[newIndex],
            });
          }
        },
      };
    },
  },
};
