import { addCapacityListener } from '../capacity';
import { addSelectionListener } from '../selection';

const ADD_LISTENER_MAP = {
  capacityChange: addCapacityListener,
  selectionChange: addSelectionListener,
};

export function addEventListener(listenerTarget, listenerFn) {
  const addListenerFn = ADD_LISTENER_MAP[listenerTarget];
  if (addListenerFn) addListenerFn(listenerFn, true);
}
