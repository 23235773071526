import { setFloor } from './modules/room';
import {
  addItem,
  configureItems,
  removeItems,
  getItems,
} from './modules/items';
import { rotateItems } from './modules/placement';
import { rotateCamera } from './modules/camera';
import { getThumbnail } from './modules/thumbnail';
import { saveConfiguration } from './modules/persistence';
import { reset } from './init';
import { addEventListener } from './modules/eventListeners';
import { setStealthTech, checkEligibility } from './modules/stealthTech';
import { enableMeasurement, disableMeasurement } from './modules/measurement';

export default {
  setStealthTech,
  checkEligibility,
  enableMeasurement,
  disableMeasurement,
  setFloor,
  addItem,
  configureItems,
  removeItems,
  getItems,
  rotateItems,
  rotateCamera,
  getThumbnail,
  saveConfiguration,
  reset,
  addEventListener,
  //   _: { configuratorState, selectItem }, // private functions to expose for testing/debugging
};
