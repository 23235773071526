// import { v4 as uuidv4 } from 'uuid';

export class Island {
  constructor() {
    // this.id = uuidv4();
    this.seats = new Set([]);
    this.sides = new Set([]);
    this.mappedSeats = {};
    // this.layout = null;
  }

  addSeat(id) {
    if (this.seats.has(id)) return false;
    return this.seats.add(id);
  }

  // deleteSeat(id) {
  //   return this.seats.delete(id);
  // }

  hasSeat(id) {
    return this.seats.has(id);
  }

  addSide(id) {
    if (this.sides.has(id)) return false;
    return this.sides.add(id);
  }

  // deleteSide(id) {
  //   return this.sides.delete(id);
  // }

  hasSide(id) {
    return this.sides.has(id);
  }

  addSeatMap(id, seat) {
    this.mappedSeats[id] = seat;
  }

  // get layout() {
  //   // TODO this.layout = ...
  //   return this.layout;
  // }
}
