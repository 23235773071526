import { CAMERA_ROTATIONS } from './rotateTool';

export function rotateCamera() {
  const currentRotation = window.threekit.playerConfigurator.getConfiguration()
    .Rotation;

  const index = CAMERA_ROTATIONS.indexOf(currentRotation);
  let newIndex = index + 1;
  if (newIndex > 7) newIndex = 0;
  const newRotationValue = CAMERA_ROTATIONS[newIndex];
  window.threekit.playerConfigurator.setConfiguration({
    Rotation: newRotationValue,
  });
}
