import { getCameraId, frameNodes } from '../camera';
import { getNonAccessories, getMeshesForItems, getItemIds } from '../items';
import { setNodeHighlighting, getSelection } from '../selection';

/* global __THREEKIT_AUTH_TOKEN__,  __THREEKIT_HOST__, __SERVER_URL__ */

// TODO: determine default width and height
export async function getThumbnail(width = 600, height = 600) {
  const THUMBNAIL_CAMERA_NAME = 'Thumbnail Camera';

  const size = {
    width: width / 4,
    height: height / 4,
  };

  const sceneCamera = window.threekit.api.player.cameraController.getActiveCamera();
  const thumbnailCamera = await getCameraId(THUMBNAIL_CAMERA_NAME);
  const nodeIds = await getMeshesForItems(getItemIds(), {
    excludeAccessories: true,
  });

  const selectionArray = Array.from(getSelection());

  await Promise.all(selectionArray.map((id) => setNodeHighlighting(id, false)));

  if (thumbnailCamera)
    window.threekit.api.player.cameraController.setActiveCamera(
      thumbnailCamera
    );

  frameNodes(nodeIds);
  const blob = await window.threekit.api.snapshotAsync({ size });
  selectionArray.map((id) => setNodeHighlighting(id, true));

  if (thumbnailCamera)
    window.threekit.api.player.cameraController.setActiveCamera(sceneCamera);

  return postThumbnail(blob);
}

const postThumbnail = async (blob) => {
  const url = `${__SERVER_URL__}/thumbnail`;

  const formData = new FormData();
  formData.append('threekitHost', __THREEKIT_HOST__);
  formData.append('threekitToken', __THREEKIT_AUTH_TOKEN__);
  formData.append('image', blob.split(';base64,').pop());
  formData.append('filename', `lovesac-${new Date().toISOString()}.png`);

  return fetch(url, {
    method: 'post',
    body: formData,
  }).then((res) => res.text());
};
