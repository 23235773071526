/**
 *
 * @param {string} itemId uuid of the item for which we're toggling the speaker icon
 * @param {bool} iconEnabled Boolean value indicating whether the speaker icon(s) for this item should be shown or hidden
 * @param {string} showTopSpeakers left/right - where top speakers show reside
 * @param {string} showSideSpeakers left/right/none - where / whether side speakers should show
 */
export async function toggleSpeakerIcon(
  itemId,
  iconEnabled,
  showTopSpeakers = 'left',
  showSideSpeakers = 'none'
) {
  const config = await window.threekit.api.player.getConfiguratorInstance({
    id: itemId,
    plug: 'Null',
    property: 'asset',
  });
  if (!config) {
    console.error(
      `Could not show speaker for itemId '${itemId}'. Configurator instance not found.`
    );
    return;
  }

  await config.setConfiguration({
    ShowStealthTech: !!iconEnabled,
    showTopSpeakers,
    showSideSpeakers,
  });
}
