import { getItem } from '../items';

// See *_Collider model Assets as the soruce for these values
const itemPolygonVerts3D = {
  seat: {
    wedge: [
      { x: -0.42, z: -0.28 },
      { x: -0.15, z: 0.37 },
      { x: 0.15, z: 0.37 },
      { x: 0.42, z: -0.28 },
    ],
    standard: [
      { x: -0.43, z: -0.36 },
      { x: -0.43, z: 0.36 },
      { x: 0.43, z: 0.36 },
      { x: 0.43, z: -0.36 },
    ],
    get standard_outdoor() {
      return this.standard;
    },
    get storage() {
      return this.standard;
    },
  },
  side: {
    standard: [
      { x: -0.435, z: -0.074 },
      { x: -0.435, z: 0.074 },
      { x: 0.435, z: 0.074 },
      { x: 0.435, z: -0.074 },
    ],
    get standard_outdoor() {
      return this.standard;
    },
    deep: [
      { x: -0.36, z: -0.078 },
      { x: -0.36, z: 0.078 },
      { x: 0.36, z: 0.078 },
      { x: 0.36, z: -0.078 },
    ],
    get deep_outdoor() {
      return this.deep;
    },
    get rollArm() {
      return this.standard;
    },
  },
};

export function getPolygonForItem(itemId) {
  const { type, key } = getItem(itemId);
  const verts = itemPolygonVerts3D[type]?.[key];
  if (!verts || !verts.length) return null;
  return verts.map(({ x, z }) => [x, z]);
}
