import { getItems, getItem, getConnectedSets } from '../items';
import {
  getConnectorData,
  getConnectorsForItem,
  getAttachment,
  getAttachmentsForItem,
  ALL_SEAT_SIDES,
  OPPOSITE_SEAT_SIDES,
  NEXT_SEAT_SIDES,
  PREVIOUS_SEAT_SIDES,
  getAttachmentsForConnector,
  sortSeatsAttachments,
  getConnectedItemOfSeatForSide,
} from '../placement/attachments';
import { toggleSpeakerIcon } from './icons';

const ERROR_CODES = {
  NO_END_STANDARD_SIDES: 'err_no_end_std_sides', // No/not enough std sides found where core speakers should reside
  WEDGE_SEAT_INELIGIBLE: 'err_wedge_seat_ineligible', // Wedge seat found for 1 seat layout
  MUST_HAVE_STANDARD_SEAT: 'err_no_std_seat', // No std seat exist
  UNKNOWN_LAYOUT: 'err_unknown_layout',
};

const STEALTH_TECH_TYPES = [null, 'core', 'optimal'];
const STEALTH_TECH_BUNDLE_TYPES = [
  '6SpeakerSactionalStealthBundle',
  '7SpeakerSactionalStealthBundle',
  '8SpeakerSactionalStealthBundle',
  '9SpeakerSactionalStealthBundle',
  '10SpeakerSactionalStealthBundle',
];

const getAvailableBundles = (bundle) => {
  const { satellites } = bundle;
  const availableBundles = [
    {
      type: 'stealthTech',
      key: STEALTH_TECH_BUNDLE_TYPES[0],
    },
  ];
  if (satellites.length) {
    availableBundles.push({
      type: 'stealthTech',
      key: STEALTH_TECH_BUNDLE_TYPES[satellites.length],
    });
  }
  return availableBundles;
};

const COMMON_LAYOUT_TYPES = ['I', 'L', 'U'];

const SPEAKER_POSITIONS = ['left', 'right'];
// * There is an implication in code that speakers have an order in
// the array - left arm then right arm of the sofa

// Current state of stealthTech bundle
const stealthTechState = {
  type: null, // core or optimal
  showIndicators: false,
  //
  cores: [],
  satellites: [],
  layout: 'I', // I, L, U and M
  items: [],
};

const state = {};

function setStealthTech(options) {
  if (!options) return;
  const { type, key, showIndicators } = options;
  const newState = {};
  if (key !== undefined) {
    const index = STEALTH_TECH_BUNDLE_TYPES.findIndex((e) => e === key);
    const bundleType = index === 0 ? 'core' : index > 0 ? 'optimal' : null;
    newState.type = bundleType;
  } else {
    newState.type = null;
  }
  if (showIndicators !== undefined) newState.showIndicators = showIndicators;

  updateStealthTechState(newState);
  console.log('Change stealth tech to', key, stealthTechState);
}

const setStateItemsFromBundle = (bundle = {}) => {
  const { layout, subwoofer, speakers, satellites } = bundle;
  const cores = [{ id: subwoofer }, ...(speakers || [])].filter(
    (e) => e && e.id
  );
  const items = [...cores];
  if (stealthTechState.type === 'optimal') {
    items.push(...(satellites || []));
  }

  if (stealthTechState.showIndicators && stealthTechState.type) {
    const allItems = getItems();

    const updateItems = {};
    stealthTechState.items.forEach((item) => {
      if (allItems.has(item.id)) {
        updateItems[item.id] = {
          ...item,
          visible: false,
        };
      }
    });
    items.forEach((item) => {
      updateItems[item.id] = {
        ...item,
        visible: true,
      };
    });
    Object.values(updateItems).forEach((item) => {
      toggleSpeakerIcon(
        item.id,
        item.visible,
        item.showTopSpeakers,
        item.showSideSpeakers
      );
    });
  }
  stealthTechState.layout = layout || null;
  stealthTechState.cores = cores;
  stealthTechState.satellites = satellites;
  stealthTechState.items = items;
};

// This is expected to be called when user wants a different option
const updateStealthTechState = (state) => {
  const { type, showIndicators } = state;
  const items = [];
  if (type) {
    items.push(...stealthTechState.cores);
  }
  if (type === 'optimal') {
    items.push(...stealthTechState.satellites);
  }
  let changed = false;
  if (
    showIndicators !== undefined &&
    showIndicators !== stealthTechState.showIndicators
  ) {
    changed = true;
    stealthTechState.showIndicators = showIndicators;
  }
  if (type !== stealthTechState.type) {
    changed = true;
  }

  if (changed) {
    const updateItems = {};
    stealthTechState.items.forEach((item) => {
      updateItems[item.id] = {
        ...item,
        visible: false,
      };
    });
    if (showIndicators)
      items.forEach((item) => {
        updateItems[item.id] = {
          ...item,
          visible: true,
        };
      });
    Object.values(updateItems).forEach((item) => {
      toggleSpeakerIcon(
        item.id,
        item.visible,
        item.showTopSpeakers,
        item.showSideSpeakers
      );
    });

    stealthTechState.type = type;
    stealthTechState.items = items;
  }
};

const LAYOUT_FUNCS = [
  // M
  (island) => {
    const { seats, sides: allSides, mappedSeats } = island;
    if (seats.size < 7) return;
    // Find the ID of a centered seat by checking if all surrounding
    // items are seats
    const centerSeatId = Object.keys(mappedSeats).find(
      (id) => mappedSeats[id].surroundBySeats
    );
    if (centerSeatId) {
      const sides = new Set(
        Array.from(allSides).filter((id) => getItem(id).key === 'standard')
      );
      // Starting from one edge, check clockwisely for seats connecting
      const centerSeatConnectors = getConnectorsForItem(centerSeatId).map((c) =>
        getConnectorData(c)
      );
      const isWing = (side) => {
        const centerConnectorToSeat1 = centerSeatConnectors.find(
          (connector) => connector.seatSide === side
        );
        // We know the item connects to center seat is a seat
        const seat1Id = getConnectorData(centerConnectorToSeat1.occupied).owner;
        const seat1 = {};
        const seat1Connectors = getConnectorsForItem(seat1Id).map((id) => {
          const connector = getConnectorData(id);
          if (
            connector.occupied &&
            !seat1[connector.seatSide] &&
            ALL_SEAT_SIDES.includes(connector.seatSide)
          ) {
            const occupant = getConnectorData(connector.occupied);
            if (occupant.occupied === id) seat1[connector.seatSide] = occupant;
          }
          return connector;
        });
        const seat1ConnectorToCenter = seat1Connectors.find(
          (connector) =>
            connector.occupied &&
            getConnectorData(connector.occupied).owner === centerSeatId
        );
        const seat2LookupSide =
          PREVIOUS_SEAT_SIDES[seat1ConnectorToCenter.seatSide];

        // Look for the next seat
        const seat1ConnectorToSeat2 = seat1Connectors.find(
          (connector) =>
            connector.seatSide === seat2LookupSide && connector.occupied
        );
        const seat2Id =
          seat1ConnectorToSeat2 &&
          getConnectorData(seat1ConnectorToSeat2.occupied).owner;
        // The connected item at this side is not a seat
        if (!seats.has(seat2Id)) return;

        const seat2 = {};
        // Look for the 3rd seat which should connect back to center seat
        const seat2Connectors = getConnectorsForItem(seat2Id).map((id) => {
          const connector = getConnectorData(id);
          if (
            connector.occupied &&
            !seat2[connector.seatSide] &&
            ALL_SEAT_SIDES.includes(connector.seatSide)
          ) {
            const occupant = getConnectorData(connector.occupied);
            if (occupant.occupied === id) seat2[connector.seatSide] = occupant;
          }
          return connector;
        });
        const seat2ConnectorToSeat1 = seat2Connectors.find(
          (connector) =>
            connector.occupied &&
            getConnectorData(connector.occupied).owner === seat1Id
        );
        const seat3LookupSide =
          PREVIOUS_SEAT_SIDES[seat2ConnectorToSeat1.seatSide];
        const seat2ConnectorToSeat3 = seat2Connectors.find(
          (connector) =>
            connector.seatSide === seat3LookupSide && connector.occupied
        );
        const seat3Id =
          seat2ConnectorToSeat3 &&
          getConnectorData(seat2ConnectorToSeat3.occupied).owner;
        if (!seats.has(seat3Id)) return;

        const seat3 = {};
        const seat3Connectors = getConnectorsForItem(seat3Id).map((id) => {
          const connector = getConnectorData(id);
          if (
            connector.occupied &&
            !seat3[connector.seatSide] &&
            ALL_SEAT_SIDES.includes(connector.seatSide)
          ) {
            const occupant = getConnectorData(connector.occupied);
            if (occupant.occupied === id) seat3[connector.seatSide] = occupant;
          }
          return connector;
        });
        const seat3ConnectorToSeat2 = seat3Connectors.find(
          (connector) =>
            connector.occupied &&
            getConnectorData(connector.occupied).owner === seat2Id
        );
        const centerSeatLookupSide =
          PREVIOUS_SEAT_SIDES[seat3ConnectorToSeat2.seatSide];
        const seat3ConnectorToCenter = seat3Connectors.find(
          (connector) =>
            connector.seatSide === centerSeatLookupSide && connector.occupied
        );
        if (
          !seat3ConnectorToCenter ||
          getConnectorData(seat3ConnectorToCenter.occupied).owner !==
            centerSeatId
        )
          return;

        return { seat1, seat2, seat3 };
      };
      for (let i = 0; i < 2; i++) {
        const side = ALL_SEAT_SIDES[i];
        const oppositeSide = ALL_SEAT_SIDES[i + 2];
        const wing1 = isWing(side);
        const wing2 = wing1 && isWing(oppositeSide);
        if (wing2) {
          const getSidesOfSeat = (seat) =>
            ALL_SEAT_SIDES.filter(
              (s) => !(seat[s] && seats.has(seat[s].owner))
            ).map((s) =>
              seat[s] && sides.has(seat[s].owner) ? seat[s].owner : null
            );

          const wing1Seat1Sides = getSidesOfSeat(wing1.seat1);
          const numOfWing1Seat1Sides = wing1Seat1Sides.filter((e) => !!e)
            .length;
          const wing1Seat2Sides = getSidesOfSeat(wing1.seat2);
          const numOfWing1Seat2Sides = wing1Seat2Sides.filter((e) => !!e)
            .length;
          const wing1Seat3Sides = getSidesOfSeat(wing1.seat3);
          const numOfWing1Seat3Sides = wing1Seat3Sides.filter((e) => !!e)
            .length;
          const wing2Seat1Sides = getSidesOfSeat(wing2.seat1);
          const numOfWing2Seat1Sides = wing2Seat1Sides.filter((e) => !!e)
            .length;
          const wing2Seat2Sides = getSidesOfSeat(wing2.seat2);
          const numOfWing2Seat2Sides = wing2Seat2Sides.filter((e) => !!e)
            .length;
          const wing2Seat3Sides = getSidesOfSeat(wing2.seat3);
          const numOfWing2Seat3Sides = wing2Seat3Sides.filter((e) => !!e)
            .length;

          if (!numOfWing1Seat2Sides || !numOfWing2Seat2Sides) {
            // No room for speakers return nothing
            return;
          }
          // No speaker can be on the same side
          if (
            numOfWing2Seat2Sides === 1 &&
            numOfWing1Seat2Sides === 1 &&
            ((wing1Seat2Sides[0] && wing2Seat2Sides[0]) ||
              (wing1Seat2Sides[1] && wing2Seat2Sides[1]))
          ) {
            return;
          }

          const speakers = [];
          const satellites = [];

          if (numOfWing2Seat2Sides - numOfWing1Seat2Sides) {
            // Only core speakers
            if (wing1Seat2Sides[0] && wing2Seat2Sides[1]) {
              // Right - Left
              speakers.push(
                { id: wing2Seat2Sides[1] },
                { id: wing1Seat2Sides[0] }
              );
            } else {
              // Left - Right
              speakers.push(
                { id: wing1Seat2Sides[1] },
                { id: wing2Seat2Sides[0] }
              );
            }
          } else {
            // Both 2nd seats have 2 sides, check 1st & 3rd seats
            if (
              numOfWing1Seat1Sides + numOfWing2Seat3Sides >
              numOfWing1Seat3Sides + numOfWing2Seat1Sides
            ) {
              // Left - Right
              speakers.push(
                { id: wing1Seat2Sides[1] },
                { id: wing2Seat2Sides[0] }
              );
              satellites.push(
                { id: wing1Seat2Sides[0] },
                { id: wing2Seat2Sides[1] }
              );
            } else {
              // Right - Left
              speakers.push(
                { id: wing2Seat2Sides[1] },
                { id: wing1Seat2Sides[0] }
              );
              satellites.push(
                { id: wing2Seat2Sides[0] },
                { id: wing1Seat2Sides[1] }
              );
            }
            satellites.map((e, i) => {
              e.showTopSpeakers = SPEAKER_POSITIONS[i];
              return e;
            });
          }
          return {
            layout: 'M',
            subwoofer: centerSeatId,
            speakers,
            satellites,
          };
        }
      }
    }
  },
  // U, L, I
  (island) => {
    const { seats, sides: allSides, mappedSeats } = island;
    if (seats.size < 2) return;
    const sides = new Set(
      Array.from(allSides).filter((id) => getItem(id).key === 'standard')
    );

    const errors = [];

    // Get seats that only connects 1 seat
    const endSeats = Object.values(mappedSeats).filter((s) => !!s.pairs);
    if (endSeats.length < 2) {
      if (state.hasRollArm)
        return { errors: [ERROR_CODES.NO_END_STANDARD_SIDES] };
      return;
    }
    /**
     * Check seat and see if it connects to another seat
     * @param {*} headNode node in path as the head
     * @param {*} connector the connector the tail starts from
     * @param {*} numOfCornersLeft
     */
    function getTailSeats(headNode, connector, numOfCornersLeft) {
      const { type } = headNode;
      const { owner: seatId, seatSide } = connector;
      const paths = [];
      if (mappedSeats[seatId]) {
        const oppositeSide = OPPOSITE_SEAT_SIDES[seatSide];
        const oppositeConnector = mappedSeats[seatId][oppositeSide];
        const { worldTransform } = mappedSeats[seatId];

        if (
          mappedSeats[seatId].pairs &&
          oppositeConnector &&
          getItem(oppositeConnector.owner).key === 'standard'
        ) {
          // This seat is an end/corner
          paths.push([
            {
              type: 'end',
              id: seatId,
              cap: oppositeConnector,
              layout: COMMON_LAYOUT_TYPES[2 - numOfCornersLeft],
              worldTransform,
            },
          ]);
        } else if (oppositeConnector) {
          // The item connected is another seat
          if (mappedSeats[oppositeConnector.owner]) {
            const item = getItem(seatId);
            if (item.key === 'wedge') {
              // const nextItem = getItem(oppositeConnector.owner);
              const previousConnector = mappedSeats[seatId][seatSide];
              const previousItem = getItem(previousConnector.owner);

              let nodeType;
              if (previousItem.key === item.key) {
                if (
                  previousConnector.seatSide === OPPOSITE_SEAT_SIDES[seatSide]
                ) {
                  if (type === 'corner') {
                    nodeType = 'corner-ext';
                  } else nodeType = 'corner';
                } else {
                  nodeType = 'middle';
                }
              } else {
                nodeType = 'corner';
              }

              const isCorner = nodeType === 'corner';

              const node = { type: nodeType, id: seatId, worldTransform };

              const tails = getTailSeats(
                node,
                oppositeConnector,
                numOfCornersLeft - (isCorner ? 1 : 0)
              );
              if (tails.length) {
                tails.forEach((tail) => {
                  // if (tail[0].type !== 'corner-ext')
                  paths.push([node].concat(tail));
                });
              }
            } else {
              const node = { type: 'middle', id: seatId, worldTransform };
              const tails = getTailSeats(
                node,
                oppositeConnector,
                numOfCornersLeft
              );
              if (tails.length) {
                tails.forEach((tail) => {
                  paths.push([node].concat(tail));
                });
              }
            }
          }
        }

        if (numOfCornersLeft > 0) {
          // 1 step counter clockwisely
          const previousSide = PREVIOUS_SEAT_SIDES[seatSide];
          const previousConnector = mappedSeats[seatId][previousSide];
          if (previousConnector && mappedSeats[previousConnector.owner]) {
            const node = { type: 'corner', id: seatId, worldTransform };
            const tails = getTailSeats(
              node,
              previousConnector,
              numOfCornersLeft - 1
            );
            if (tails.length) {
              tails.forEach((tail) => {
                if (tail[0].type !== 'corner') {
                  // It doesn't sound correct that a corner connects to another
                  paths.push([node].concat(tail));
                }
              });
            }
          }
        }
      }

      return paths;
    }
    const paths = [];
    // clockwisely to get possible layouts
    endSeats.forEach((endSeat) => {
      const { id, pairs } = endSeat;
      pairs.forEach((pair) => {
        const { seat, side } = pair;
        const { seatSide } = seat;
        const connector = mappedSeats[id][seatSide];
        const { worldTransform } = mappedSeats[id];

        const node = {
          type: 'end',
          id,
          worldTransform,
          cap: mappedSeats[id][side.seatSide],
        };

        const tails = getTailSeats(node, connector, 2);
        if (tails.length) {
          tails.forEach((tail) => {
            const path = [node].concat(tail);
            const { layout } = path[path.length - 1];
            if (
              !(
                layout === 'I' &&
                paths.some(
                  (p) =>
                    p[p.length - 1].layout === 'I' &&
                    p.length === path.length &&
                    p[0].id === path[path.length - 1].id &&
                    path[0].id === p[p.length - 1].id
                )
              )
            )
              paths.push(path);
          });
        }
      });
    });

    const getBundleFromPaths = (pathArray) => {
      for (const path of pathArray) {
        const { layout } = path[path.length - 1];
        // Left - Right
        const speakers = [
          { id: path[path.length - 1].cap.owner },
          { id: path[0].cap.owner },
        ];

        if (layout === 'U' && path.length > 5) {
          const [rightCorner, leftCorner] = path.filter((ele, index) => {
            if (ele.type === 'corner') {
              path[index].index = index;
              return ele;
            }
            return null;
          });
          const cornerExts = path.filter((ele) => {
            return ele.type === 'corner-ext';
          });
          if (leftCorner && rightCorner) {
            const subwooferIndex = Math.ceil(
              (leftCorner.index + rightCorner.index) / 2
            );
            let subwoofer = path[subwooferIndex].id;

            if (getItem(subwoofer).key === 'wedge') {
              let i = 1;
              while (path[subwooferIndex - i] || path[subwooferIndex + i]) {
                const prevNode = path[subwooferIndex - i];
                const nextNode = path[subwooferIndex + i];
                if (prevNode && getItem(prevNode.id).key !== 'wedge') {
                  subwoofer = prevNode.id;
                  break;
                } else if (nextNode && getItem(nextNode.id).key !== 'wedge') {
                  subwoofer = nextNode.id;
                  break;
                } else i++;
              }
            }

            const leftCornerSides = ALL_SEAT_SIDES.map(
              (side) => mappedSeats[leftCorner.id][side]
            ).filter((connector) => connector && sides.has(connector.owner));
            const rightCornerSides = ALL_SEAT_SIDES.map(
              (side) => mappedSeats[rightCorner.id][side]
            ).filter((connector) => connector && sides.has(connector.owner));

            const satellites = [];
            leftCornerSides.forEach((c, i) => {
              satellites.push({
                id: c.owner,
                showTopSpeakers: SPEAKER_POSITIONS[i],
              });
            });
            rightCornerSides.forEach((c, i) => {
              satellites.push({
                id: c.owner,
                showTopSpeakers: SPEAKER_POSITIONS[i],
              });
            });
            cornerExts.forEach((o) => {
              const connector = mappedSeats[o.id].back;
              if (connector && sides.has(connector.owner)) {
                satellites.push({
                  id: connector.owner,
                  showTopSpeakers: SPEAKER_POSITIONS[1],
                });
              }
            });
            return {
              layout,
              subwoofer,
              speakers,
              satellites,
            };
          }
        } else if (layout === 'L' && path.length > 2) {
          // We can't really tell if seats are left or right winged,
          // for now use avg Z of nodes' worldTransform
          const cornerItemIndex = path.findIndex(
            (ele) => ele.type === 'corner'
          );
          let avgZ1 = 0;
          let avgZ2 = 0;
          for (let i = 0; i < path.length; i++) {
            const { worldTransform } = path[i];
            if (i < cornerItemIndex) {
              avgZ1 += worldTransform.z;
            } else if (i > cornerItemIndex) {
              avgZ2 += worldTransform.z;
            }
          }

          const leftWinged =
            avgZ1 / (cornerItemIndex + 1) <
            avgZ2 / (path.length - cornerItemIndex - 1);

          const subwooferIndex = leftWinged
            ? Math.ceil(cornerItemIndex / 2)
            : path.length - Math.ceil((path.length - cornerItemIndex) / 2);
          let subwoofer = path[subwooferIndex].id;

          if (getItem(subwoofer).key === 'wedge') {
            let i = 1;
            while (path[subwooferIndex - i] || path[subwooferIndex + i]) {
              const prevNode = path[subwooferIndex - i];
              const nextNode = path[subwooferIndex + i];
              if (prevNode && getItem(prevNode.id).key !== 'wedge') {
                subwoofer = prevNode.id;
                break;
              } else if (nextNode && getItem(nextNode.id).key !== 'wedge') {
                subwoofer = nextNode.id;
                break;
              } else i++;
            }
          }

          const satellites = [];
          const cornerItemId = path[cornerItemIndex].id;
          const cornerExts = path.filter((ele) => {
            return ele.type === 'corner-ext';
          });
          // Get the standard sides that attach to the corner seat of L shape
          const cornerSides = ALL_SEAT_SIDES.map(
            (side) => mappedSeats[cornerItemId][side]
          ).filter((connector) => connector && sides.has(connector.owner));

          if (cornerExts.length) {
            const connector = mappedSeats[cornerExts[0].id].back;
            if (connector && sides.has(connector.owner)) {
              cornerSides.push(connector);
            }
          }

          if (cornerSides.length === 2) {
            const mainSatelliteSeatInfo =
              path[leftWinged ? 0 : path.length - 1];
            const mainSatelliteSeat = mappedSeats[mainSatelliteSeatInfo.id];
            const mainSatelliteSeatEndSideIndex = ALL_SEAT_SIDES.findIndex(
              (side) =>
                mainSatelliteSeat[side] &&
                mainSatelliteSeat[side].owner ===
                  mainSatelliteSeatInfo.cap.owner
            );
            const nextSide =
              ALL_SEAT_SIDES[
                (mainSatelliteSeatEndSideIndex + (leftWinged ? 1 : 3)) %
                  ALL_SEAT_SIDES.length
              ];
            const nextItemConnector =
              mappedSeats[mainSatelliteSeatInfo.id][nextSide];
            if (nextItemConnector && sides.has(nextItemConnector.owner)) {
              satellites.push({
                id: nextItemConnector.owner,
                showTopSpeakers: SPEAKER_POSITIONS[leftWinged & 1],
              });
            }
            satellites.push(
              ...cornerSides.map((c, i) => {
                return { id: c.owner, showTopSpeakers: SPEAKER_POSITIONS[i] };
              })
            );
          }
          return {
            layout,
            subwoofer,
            speakers,
            satellites,
          };
        } else if (layout === 'I' && path.length > 1) {
          const firstSeat = mappedSeats[path[0].id];
          const lastSeat = mappedSeats[path[path.length - 1].id];
          // The sides at the end of each end seat
          const firstSeatEndSide = ALL_SEAT_SIDES.find(
            (side) =>
              firstSeat[side] && firstSeat[side].owner === path[0].cap.owner
          );
          const lastSeatEndSide = ALL_SEAT_SIDES.find(
            (side) =>
              lastSeat[side] &&
              lastSeat[side].owner === path[path.length - 1].cap.owner
          );
          const satellites = [];
          // To tell left from right:
          // Assume first seat is on the right side
          // and last seat is on the left side
          let reversed = false;
          const nextSideItem1 = firstSeat[NEXT_SEAT_SIDES[firstSeatEndSide]];
          const prevSideItem1 =
            firstSeat[PREVIOUS_SEAT_SIDES[firstSeatEndSide]];
          const nextSideItem2 = lastSeat[NEXT_SEAT_SIDES[lastSeatEndSide]];
          const prevSideItem2 = lastSeat[PREVIOUS_SEAT_SIDES[lastSeatEndSide]];
          // optimal bundle available for I layout only more than 3
          // seats connect in a row
          if (
            nextSideItem1 &&
            prevSideItem2 &&
            sides.has(nextSideItem1.owner) &&
            sides.has(prevSideItem2.owner) &&
            path.length > 3
          ) {
            satellites.push(
              { id: prevSideItem2.owner },
              { id: nextSideItem1.owner }
            );
          } else if (
            nextSideItem2 &&
            prevSideItem1 &&
            sides.has(nextSideItem2.owner) &&
            sides.has(prevSideItem1.owner)
          ) {
            if (path.length > 3)
              satellites.push(
                { id: prevSideItem1.owner },
                { id: nextSideItem2.owner }
              );
            reversed = true;
          }
          satellites.map((e, i) => {
            e.showTopSpeakers = SPEAKER_POSITIONS[i];
            return e;
          });
          if (reversed) {
            path.reverse();
            speakers.reverse();
          }

          const subwoofer = path[Math.floor(path.length / 2)].id;
          return {
            layout,
            subwoofer,
            speakers,
            satellites,
          };
        }
      }
    };
    const sortedPaths = paths.sort((a, b) => b.length - a.length);

    const bundle = getBundleFromPaths(sortedPaths);
    console.log('paths & result bundle', sortedPaths, bundle);

    if (!bundle) {
      errors.push(ERROR_CODES.UNKNOWN_LAYOUT);
      return { errors };
    }

    return bundle;
  },
  // I: 1 seat
  (island) => {
    const { seats, mappedSeats } = island;

    // Get seats that only connects 1 seat
    const endSeats = Object.values(mappedSeats).filter((s) => !!s.pairs);

    if (seats.size === 1) {
      const id = Array.from(seats)[0];
      const { sides: connectedSides } = sortSeatsAttachments(id);
      const connectedStandardSides =
        connectedSides &&
        connectedSides.filter((attachment) => {
          const item = getItem(attachment.otherConnector.owner);
          return item.key === 'standard';
        });

      const seatItem = getItem(id);
      const isWedge = seatItem.key === 'wedge';

      const seat = {};
      const pairs = [];
      if (connectedStandardSides && !isWedge) {
        connectedStandardSides.forEach((seatAttachment) => {
          const { seatSide } = seatAttachment.thisConnector;
          if (seat[seatSide]) {
            return; // side handled
          }
          seat[seatSide] = seatAttachment.otherConnector;
          const otherSide = OPPOSITE_SEAT_SIDES[seatSide];
          const findSide = connectedStandardSides.find(
            (attachment) => attachment.thisConnector.seatSide === otherSide
          );
          if (findSide) {
            pairs.push({
              [otherSide]: findSide.thisConnector,
              [seatSide]: seatAttachment.thisConnector,
            });
            seat[otherSide] = findSide.otherConnector;
          }
        });
      }
      if (pairs.length === 1) {
        const speakers = Object.values(pairs[0]).map((connector) => {
          return { id: getConnectorData(connector.occupied).owner };
        });
        return {
          layout: 'I',
          subwoofer: id,
          speakers,
          satellites: [],
        };
      } else {
        const errors = [];

        if (state.hasRollArm) errors.push(ERROR_CODES.NO_END_STANDARD_SIDES);
        if (isWedge) errors.push(ERROR_CODES.WEDGE_SEAT_INELIGIBLE);
        if (errors.length)
          return {
            errors,
          };
        else return;
      }
    } else if (endSeats.length === 1) {
      const { id, pairs } = endSeats[0];
      if (pairs.length === 1) {
        const backSide = ALL_SEAT_SIDES.find(
          (s) => s === pairs[0].side.seatSide
        );

        const leftSide = NEXT_SEAT_SIDES[backSide];
        const rightSide = PREVIOUS_SEAT_SIDES[backSide];

        if (endSeats[0][leftSide] && endSeats[0][rightSide]) {
          const speakers = [leftSide, rightSide]
            .map((side) => {
              const { owner } = endSeats[0][side];
              const item = getItem(owner);
              if (item.type === 'side' && item.key === 'standard')
                return { id: owner };
            })
            .filter((e) => !!e);
          if (speakers.length === 2)
            return {
              layout: 'I',
              subwoofer: id,
              speakers,
              satellites: [],
            };
        }
      }
    }
    if (state.hasRollArm)
      return {
        errors: [ERROR_CODES.NO_END_STANDARD_SIDES],
      };
  },
];

// const getCenterSeatIds = (island) => {
//   const { seats, sides, mappedSeats } = island;
//   if (seats.size < 7) return [];
//   // Find the ID of a centered seat by checking if all surrounding
//   // items are seats
//   const centerSeatIds = Object.keys(mappedSeats).filter((id) => {
//     return mappedSeats[id].surroundBySeats;
//   });
//   console.log(centerSeatIds);
//   return centerSeatIds;
// };

const getStealthTechBundlesFromSets = (sets, update = false) => {
  const allItems = Array.from(getItems().keys());
  Object.keys(state).forEach((key) => {
    state[key] = false;
  });
  let seatCount = 0;
  allItems.forEach((id) => {
    const item = getItem(id);
    if (item.type === 'seat') {
      seatCount++;
      if (item.key === 'standard') state.hasStdSeat = true;
      else if (item.key === 'storage') {
        state.hasStorageSeat = true;
      }
    } else if (item.key === 'rollArm' && item.type === 'side') {
      state.hasRollArm = true;
    }
  });

  const continent = sets[0];

  // Keep a record of errors for each func call
  const stepErrors = [];
  let bundle;
  if (!(!continent || continent.seats.size < 1)) {
    for (const func of LAYOUT_FUNCS) {
      const result = func(continent);
      if (result) {
        if (result.layout) {
          const { speakers } = result;
          speakers.forEach((e, i) => {
            e.showTopSpeakers = SPEAKER_POSITIONS[i];
            e.showSideSpeakers = SPEAKER_POSITIONS[1 - i];
          });
          bundle = result;
          break;
        } else if (result.errors) {
          stepErrors.push(result.errors);
        }
      }
    }
  }

  const { hasStdSeat, hasStorageSeat } = state;

  const stealthTechEligible =
    bundle && hasStdSeat ? getAvailableBundles(bundle) : [];

  if (update) {
    setStateItemsFromBundle(hasStdSeat && bundle);
  }

  if (stealthTechEligible.length) return { stealthTechEligible };
  else {
    const stealthTechErrors = stepErrors.pop() || [];
    if (!hasStdSeat && hasStorageSeat && seatCount > 1) {
      stealthTechErrors.push(ERROR_CODES.MUST_HAVE_STANDARD_SEAT);
    }
    return { stealthTechEligible, stealthTechErrors };
  }
};

const checkEligibility = (itemId) => {
  // Try getStealthTechBundles excluding the itemId
  const items = getItems();
  items.delete(itemId);
  const { stealthTechEligible: bundles } = getStealthTechBundlesFromSets(
    getConnectedSets(Array.from(items.keys()), [itemId])
  );

  console.log(`Bundles if to remove ${itemId}`, bundles);

  return bundles;
};

export { checkEligibility, setStealthTech, getStealthTechBundlesFromSets };
