/* global __THREEKIT_AUTH_TOKEN__ */

import { initPlayer } from './initPlayer';
import { getInitialConfiguration } from './persistence';
import publicApi from './api';
import { addCapacityListener } from './items';

const stageId = '83b2944e-a0d0-4707-8cfe-6708f713f996';
const assetId = '5199135e-e3d2-4c97-9203-5422f84dada3';

export async function init(params) {
  console.log('Threekit: configurator init start');
  const { el, onCapacityChange } = params;
  // TODO: Clear or reuse any previous configurator

  const initialConfiguration = await getInitialConfiguration(params);

  const playerApi = await initPlayer(
    el,
    __THREEKIT_AUTH_TOKEN__,
    assetId,
    stageId,
    initialConfiguration
  );

  window.threekit.api = playerApi;
  window.threekit.configurator = publicApi;

  if (onCapacityChange) addCapacityListener(onCapacityChange, true);

  console.log('Threekit: configurator init complete');
}
