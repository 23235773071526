import '@babel/polyfill'; // needed to transpile async/await in babel-transpiled webpack output
import Rollbar from 'rollbar';
import { init as init3D } from './v1/index';
import { init as initVRay } from './v2/vray';

// include and initialize the rollbar library with your access token
const rollbar = new Rollbar({
  accessToken: '1f9a9138b8454c6aafbe393005663bde',
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: { log: false }, // if true, it catches errors which then have rollbar as the top-level source map trace in the console. This effects BA and TK code alike
});

function init(params) {
  console.log('threekit init:', params);
  const { productCategory } = params;
  // Note: throw pillow vray implementation has been delayed duntil after initial sac/squattoman launch
  if (
    productCategory === 'sac' ||
    productCategory === 'squattoman' ||
    productCategory === 'throwPillow'
  )
    return initVRay(params);
  else return init3D(params);
}

window.threekit = { init };
