/* global __THREEKIT_AUTH_TOKEN__,  __THREEKIT_HOST__, __THREEKIT_ORG__ */

export const getSavedConfiguration = (configId) => {
  const url = `${__THREEKIT_HOST__}/api/configurations/${configId}?bearer_token=${__THREEKIT_AUTH_TOKEN__}`;

  return fetch(url, {
    method: 'get',
  })
    .then((res) => res.json())
    .catch((e) => console.error(e));
};

export const postConfiguration = (configuration, productVersion) => {
  const url = `${__THREEKIT_HOST__}/api/configurations?bearer_token=${__THREEKIT_AUTH_TOKEN__}`;

  const body = new FormData();
  body.append('orgId', __THREEKIT_ORG__);
  body.append('productId', window.threekit.api.assetId);
  body.append('productVersion', productVersion);
  body.append('variant', JSON.stringify(configuration));

  return fetch(url, {
    method: 'post',
    body,
  })
    .then((res) => res.json())
    .catch((e) => console.error(e));
};
