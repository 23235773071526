/* global __THREEKIT_AUTH_TOKEN__,  __THREEKIT_HOST__, __SERVER_URL__ */

export async function getThumbnail() {
  const blob = await window.threekit.api.snapshotAsync();

  return postThumbnail(blob);
}

const postThumbnail = async (blob) => {
  const url = `${__SERVER_URL__}/thumbnail`;

  const formData = new FormData();
  formData.append('threekitHost', __THREEKIT_HOST__);
  formData.append('threekitToken', __THREEKIT_AUTH_TOKEN__);
  formData.append('image', blob.split(';base64,').pop());
  formData.append('filename', `lovesac-${new Date().toISOString()}.png`);

  return fetch(url, {
    method: 'post',
    body: formData,
  }).then((res) => res.text());
};
