/* global __USE_PLAYER_CACHING__ */

import { rotateTool } from './rotateTool';

export async function initPlayer(
  elId,
  authToken,
  assetId,
  stageId,
  initialConfiguration
) {
  console.log(
    'Threekit: Applying initial player configuration - ',
    initialConfiguration
  );
  const api = await window.threekitPlayer({
    authToken,
    el: document.getElementById(elId),
    assetId,
    // stageId,
    initialConfiguration,
    showConfigurator: false,
    display: 'image',
    cache: {
      maxAge: 31536000,
      scope: 'v1.11',
    },
  });

  api.enableApi('player');

  await api.when('loaded');

  const configurator = await api.getConfigurator();
  await configurator.prefetchAttributes(['Rotation']);
  window.threekit.playerConfigurator = configurator;

  // api.tools.addTool(rotateTool);
  api.tools.addTool('zoom');

  // turnTableMobileOnly is what causes the default orbit tool to scroll on
  // vertical swipe, instead of orbiting the camera vertically. In our case, we
  // don't want/need that behaviour, since our player is not fullscreen so the
  // user can still scroll by just swiping the non-player portion of the view
  // (ie BA's UI). So by setting turnTableMobileOnly to false, we retain the
  // ability for the user to orbit vertically, as requested by the client.
  // api.tools.setTool('orbit', { options: { turnTableMobileOnly: false } });

  const threekitElem = document.getElementById(elId);
  replaceLogoUrl(threekitElem).catch((err) => {
    console.log('Error encountered replacing logo url', err);
  });

  return api;
}

async function replaceLogoUrl(parentElem) {
  const startTime = new Date();

  const check = () => {
    const logo = Array.from(
      parentElem.getElementsByTagName('a')
    ).filter((elem) => elem.className.includes('logo'))[0];
    if (!logo) {
      if (new Date() - startTime < 120000)
        return new Promise((resolve) => setTimeout(resolve, 50)).then(check);
    } else logo.href = 'https://www.threekit.com/lovesac';
  };
  return check();
}
