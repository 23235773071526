import { initPlayer, initializeConfiguration } from './init';
import publicApi from './publicApi';
import { addCapacityListener } from './modules/capacity';
import { getSavedConfiguration } from './modules/persistence';
import { addSelectionListener } from './modules/selection';
import { getCameraId } from './modules/camera';
import {
  setSceneState,
  DEFAULT_SCENE_STATE,
  mapSceneConfigurationToTKConfiguration,
  buildTKSceneConfiguration,
} from './modules/room';

/* global __THREEKIT_AUTH_TOKEN__ */

export async function init(params) {
  let { configurationId } = params;
  const {
    el,
    initialConfiguration,
    onSelectionChange,
    onCapacityChange,
  } = params;
  // TODO: Clear or reuse any previous configurator

  if (!configurationId && !initialConfiguration) {
    const urlParams = new URLSearchParams(window.location.search);
    configurationId = urlParams.get('threekitConfigurationId');
  }

  const roomConfiguratorCatalogItemId = 'bfc5b7b0-a966-4af5-9e18-c2407fe60247';

  // 1) Init configuration - either from saved id or object literal data
  let initialConfigurationData;
  if (configurationId) {
    const { variant } = await getSavedConfiguration(configurationId);
    initialConfigurationData = variant;
  } else if (initialConfiguration)
    initialConfigurationData = initialConfiguration;

  // let threekitConfig =
  // const tkConfig = mapInitConfigToThreekit()

  const initialSceneConfig = DEFAULT_SCENE_STATE;

  if (initialConfigurationData) {
    const overrideSceneConfig = buildTKSceneConfiguration(
      initialConfigurationData
    );
    Object.assign(initialSceneConfig, overrideSceneConfig);
  }

  setSceneState(initialSceneConfig);

  const tkSceneConfig = mapSceneConfigurationToTKConfiguration(
    initialSceneConfig
  );

  const playerApi = await initPlayer(
    el,
    __THREEKIT_AUTH_TOKEN__,
    roomConfiguratorCatalogItemId,
    tkSceneConfig
  );

  window.threekit.api = playerApi;

  // window.configurator = publicApi; // expose directly on window for dev convenience
  window.threekit.configurator = publicApi; // configurator interface for BlueAcorn

  // Note: make sure window vars above are exposed before adding listeners.
  // Listeners might expect the configurator api to exist
  if (onSelectionChange) addSelectionListener(onSelectionChange);
  if (onCapacityChange) addCapacityListener(onCapacityChange);

  if (initialConfigurationData)
    await initializeConfiguration(initialConfigurationData, false);
}
