import { v4 as uuidv4 } from 'uuid';

// Note: In phase 1, BA swapped sac/throwPillow products by calling removeItems
// to remove the first item, then addItem to add the one we're swapping to. A
// lot of the implementation below is solely for backwards compatibility to
// maintain the interface (functions and their inputs/outputs) BA expects

const FABRIC_CODE_METADATA_KEY = 'Internal ID (Fabric ID from Item table)';
export const DEFAULT_FABRIC_CONFIG = baFabricToSceneConfig({
  soft: '760', // 760 = Charcoal Grey Corded Velvet
  trim: null,
});

export const DEFAULT_PRODUCT_CONFIG = { Product: '' };

const state = new Map();

let capacityListener = null;

// Note: sizes in feet
const itemMetaData = {
  sac: {
    gamerSac: {
      assetName: 'Gamersac',
      seatingCapacity: 1,
      size: { width: 2.5, depth: 2.5, height: 2.666666 },
    },
    citySac: {
      assetName: 'Citysac',
      seatingCapacity: 1,
      size: { width: 3, depth: 3, height: 3.25 },
    },
    pillowSac: {
      assetName: 'PillowSac',
      seatingCapacity: 2,
      size: { width: 4.5, depth: 6.25, height: 4.5 },
    },
    movieSac: {
      assetName: 'MovieSac',
      seatingCapacity: 2,
      size: { width: 4, depth: 4, height: 3.583333 },
    },
    superSac: {
      assetName: 'Supersac',
      seatingCapacity: 3,
      size: { width: 5, depth: 5, height: 3.833333 },
    },
    bigOne: {
      assetName: 'BigOne',
      seatingCapacity: 4,
      size: { width: 6, depth: 6, height: 4.083333 },
    },
  },
  squattoman: {
    squattoman: {
      assetName: 'Squattoman',
      seatingCapacity: 1,
      size: { width: 1.666666, depth: 1.666666, height: 1.5 },
    },
  },
  throwPillow: {
    '18x18': {
      assetName: 'Throw Pillow 18x18',
      seatingCapacity: 0,
      size: { width: 1.5, depth: 0.5, height: 1.5 },
    },
    '24x16': {
      assetName: 'Throw Pillow 24x16',
      seatingCapacity: 0,
      size: { width: 2, depth: 0.666666, height: 1.333333 },
    },
    '24x24': {
      assetName: 'Throw Pillow 24x24',
      seatingCapacity: 0,
      size: { width: 2, depth: 0.75, height: 2 },
    },
  },
};

export function baItemToSceneConfig(baItem) {
  const { type, key, configuration } = baItem;

  const fabricConfig = configuration?.fabric
    ? baFabricToSceneConfig(configuration.fabric)
    : {};

  return {
    ...fabricConfig,
    Product: {
      query: { metadata: { itemType_vray: type, itemKey_vray: key } },
    },
  };
}

export async function addItem(item, updatePlayer = true) {
  console.log(`Threekit: Will add item ${item.type}-${item.key}`, updatePlayer);
  const { type, key, configuration, id } = item;
  const itemType = itemMetaData[type];
  if (!itemType) throw new Error(`Unknown item of type ${type}`);

  // Clear old items, since this configurator assumes one asset at a time
  const allItemIds = Array.from(getItems().keys());
  await removeItems(allItemIds, updatePlayer);

  const itemId = id || uuidv4();

  state.set(itemId, { id: itemId, type, key, configuration });

  if (updatePlayer) {
    const configurator = await window.threekit.api.getConfigurator();
    const tkConfig = baItemToSceneConfig(item);
    configurator.setConfiguration(tkConfig);

    updateCapacity();
  }

  console.log(`Threekit: Did add item ${item.type}-${item.key}`, updatePlayer);

  return itemId;
}

export async function removeItems(itemIds = [], updatePlayer = true) {
  let itemDeleted = false;
  itemIds.forEach((itemId) => {
    const deleted = state.delete(itemId);
    itemDeleted = itemDeleted || deleted;
  });

  // since our new sac vray configurator assumes a singleton asset (ie the value
  // of the Asset attribute), if any item was removed, we assume it is the
  // singleton and clear the Asset attribute
  if (itemDeleted && updatePlayer) {
    const configurator = await window.threekit.api.getConfigurator();
    await configurator.setConfiguration({ Product: '' });

    updateCapacity();
  }
}

function updateItemState(itemId, config) {
  const item = state.get(itemId);
  if (!item) {
    console.warn(
      `Trying to update item with id ${itemId}, which does not exist`
    );
    return;
  }
  if (!item.configuration) item.configuration = config;
  else {
    Object.entries(config).forEach(([key, value]) => {
      if (typeof value === 'object')
        item.configuration[key] = { ...item.configuration[key], ...value };
      else item.configuration[key] = value;
    });
  }
}

function getFabricQuery(fabricCode) {
  return { query: { metadata: { [FABRIC_CODE_METADATA_KEY]: fabricCode } } };
}

function baFabricToSceneConfig(baFabric) {
  if (typeof baFabric === 'string') return { Fabric: getFabricQuery(baFabric) };
  if (typeof baFabric === 'object') {
    const globalFabrics = {};
    if (baFabric.hasOwnProperty('soft'))
      globalFabrics.Fabric = getFabricQuery(baFabric.soft);
    if (baFabric.hasOwnProperty('hard'))
      globalFabrics.Fabric = getFabricQuery(baFabric.hard);
    if (baFabric.hasOwnProperty('trim'))
      globalFabrics.Trim = getFabricQuery(baFabric.trim);
    return globalFabrics;
  }
  return {};
}

async function applyFabricConfiguration(baFabricConfig) {
  if (!baFabricConfig) return;

  const tkFabricConfig = baFabricToSceneConfig(baFabricConfig);

  const configurator = await window.threekit.api.getConfigurator();
  configurator.setConfiguration(tkFabricConfig);
}

export async function configureItems(items = [], config) {
  // console.log('Threekit: configurator configureItems start - ', items, config);
  items.forEach((itemId) => {
    updateItemState(itemId, config);
  });

  if (config.fabric) {
    await applyFabricConfiguration(config.fabric);
  }
  // console.log('Threekit: configurator configureItems finish - ', items, config);
}

export function getItemMetaData(itemType, itemKey) {
  const metadata = itemMetaData?.[itemType]?.[itemKey];
  return metadata;
}

export function getItems() {
  return new Map(state);
}

export function addCapacityListener(listenerFn, fireOnInit = false) {
  capacityListener = listenerFn;
  if (fireOnInit) updateCapacity();
}

function updateCapacity() {
  const items = getItems();

  // backPillowsUsed not applicable to vray sacs, but include anyway for
  // backwards compatibility
  const capacity = { backPillowsUsed: 0 };

  const item = items.values().next().value;
  if (!item) {
    capacity.width = 0;
    capacity.height = 0;
    capacity.depth = 0;
    capacity.seating = 0;
  } else {
    const itemData = getItemMetaData(item.type, item.key);
    capacity.seating = itemData.seatingCapacity;
    capacity.width = itemData.size.width;
    capacity.depth = itemData.size.depth;
    capacity.height = itemData.size.height;
  }

  if (capacityListener) {
    // console.log('calling capacity listener with capacity', capacity);
    capacityListener.call(null, capacity);
  }
}
